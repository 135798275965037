/*
Script by : Clem
--------------------------------------------
||       Gestion modale inscription       ||
--------------------------------------------
*/


if($('#modal_inscription').length) {

    // GESTION DU REMPLISSAGE DE LA MODALE AU CLIC SUR UN BOUTON D'INSCRIPTION
    $('.event_table .person.user.empty').click(function (e) { 
        e.preventDefault();
        
        // Récupération des informations de l'inscription
        clicked_element = $(this);
        place_nb = $(this).prevAll().length + 1;
        event_id = $(this).parents('.event_container').data('eventid');
        poste_id = $(this).parent().data('posteid');
        titre_event = $(this).parents('.event_container').find('.titre_event').html();
        desc_event = $(this).parents('.event_container').find('.desc_container').html();
        poste_demande = $(this).parents('.event_table').find('.thead .table-cell:nth-child('+poste_id+')').html();
        // console.log(titre_event);
        // console.log('poste_id : '+poste_id);
        // console.log('place_nb : '+place_nb);
        
        // Génération de l'insc_string
        insc_string = '';
        $i = 0;
        $(this).parent().find('.person').each(function () { 
            $i++;
            nom = $(this).find('.nom').html();
            if(nom == 'Poste libre') { nom = 'empty' }
            
            if($i == place_nb) {
                insc_string += '%currentinsc%|';
            } else {
                insc_string += nom+'|';
            }
        });
        insc_string = insc_string.slice(0,-1);

        // Mise à jour et affichage de la modale de confirmation
        $('#modal_inscription').find('.eventname_field').html(titre_event);
        $('#modal_inscription').find('.desc_field').html(desc_event);
        $('#modal_inscription').find('.poste_field').html(poste_demande);
        $('#modal_inscription').find('.hidden_insc_string').html(insc_string);
        $('#modal_inscription').find('.hidden_eventid').html(event_id);
        $('#modal_inscription').find('.hidden_posteid').html(poste_id);
        $('#modal_inscription').modal('show');
        
    });
    
    // GESTION DE L'ENVOI DES DONNÉES À LA CONFIRMATION DE L'INSCRIPTION
    $('#modal_inscription .btn_valider').click(function (e) { 
        e.preventDefault();

        // Récupération infos
        date_event = $('#modal_inscription .eventname_field .date').html();
        nom_event = $('#modal_inscription .eventname_field .nom').html();
        //postes_libres = $('#modal_inscription .eventname_field .postes_libres').html();
        postes_libres = $('#modal_inscription .eventname_field .postes_pris_next').html();
        poste_demande = $('#modal_inscription .poste_field').html();
        event_id = $('#modal_inscription .hidden_eventid').html();
        poste_id = $('#modal_inscription .hidden_posteid').html();
        insc_string = $('#modal_inscription .hidden_insc_string').html();
        nom_benevole = $('#input_name').val();
        message_benevole = $('#input_message').val();

        // Traitement données avant envoi
        insc_string = insc_string.replace('%currentinsc%', nom_benevole);

        ajax_url = '/php/add_inscription.php?date_event='+date_event
        +'&nom_event='+nom_event
        +'&postes_libres='+postes_libres
        +'&poste_demande='+poste_demande
        +'&event_id='+event_id
        +'&poste_id='+poste_id
        +'&insc_string='+insc_string
        +'&nom_benevole='+nom_benevole
        +'&message_benevole='+message_benevole
        +'&send_email=1';
        sanitized_ajax_url = encodeURI(ajax_url);
        sanitized_ajax_url = sanitized_ajax_url.replace('#', '&num;');

        $.ajax({
            type: 'GET',
            url: sanitized_ajax_url,
            success: function(data) {
                //console.log(data);
                
                $('#modal_inscription').modal('hide');
                $(clicked_element).find('.nom').html(nom_benevole);
                $(clicked_element).removeClass('empty');
                $(clicked_element).addClass('taken');
                $(clicked_element).addClass('recent');

                $('.insc_message.ok').addClass('active');
                setTimeout(function(){ $('.insc_message.ok').removeClass('active'); }, 2000);

                if(debug_mode == true) { 
                    console.log('Succès de l\'ajout en BDD !'); 
                } 
            },
            error: function(data) {
                //console.log(data);
                
                $('.insc_message.failed').addClass('active');
                setTimeout(function(){ $('.insc_message.failed').removeClass('active'); }, 2000);

                if(debug_mode == true) { 
                    console.log('Echec de l\'ajout en BDD !'); 
                } 
            }
        });
    });
}