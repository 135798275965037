/*
Script by : Clem
--------------------------------------------
||       Gestion modale inscription       ||
--------------------------------------------
*/


if($('.add_emplacement').length) {

    // GESTION DU REMPLISSAGE DE LA MODALE AU CLIC SUR UN BOUTON DE MODIFICATION
    $('.event_table .add_emplacement ').click(function (e) { 
        e.preventDefault();
        
        // Récupération des informations de l'inscription
        clicked_element = $(this);
        event_id = $(this).parents('.event_container').data('eventid');
        poste_id = $(this).parent().parent().data('posteid');
        titre_event = $(this).parents('.event_container').find('.titre_event').html();
        
        // Génération de l'insc_string
        insc_string = '';
        $i = 0;
        $(this).parent().parent().find('.person').each(function () { 
            $i++;
            nom = $(this).find('.nom').html();
            if(nom == 'Poste libre') { nom = 'empty' }
            insc_string += nom+'|';
        });
        insc_string = insc_string+'empty';
        //console.log(insc_string);

        ajax_url = '/php/add_inscription.php?nom_event='+titre_event+'&event_id='+event_id+'&poste_id='+poste_id+'&insc_string='+insc_string;
        sanitized_ajax_url = encodeURI(ajax_url);
        sanitized_ajax_url = sanitized_ajax_url.replace('#', '&num;');

        console.log('ajax_url : '+ajax_url);
        console.log('sanitized_ajax_url : '+sanitized_ajax_url);

        $.ajax({
            type: 'GET',
            url: sanitized_ajax_url,
            success: function(data) {
                new_emplacement_html = '<div class="person recent"><span class="nom">Poste libre</span></div>';
                $(clicked_element).parent().parent().append(new_emplacement_html);

                $('.add_message.ok').addClass('active');
                setTimeout(function(){ $('.add_message.ok').removeClass('active'); }, 1000);

                if(debug_mode == true) { 
                    console.log('Succès de l\'ajout d\'un emplacement !'); 
                } 
            },
            error: function(data) {
                //console.log(data);
                
                $('.add_message.failed').addClass('active');
                setTimeout(function(){ $('.add_message.failed').removeClass('active'); }, 2000);

                if(debug_mode == true) { 
                    console.log('Echec de l\'ajout d\'un emplacement...'); 
                } 
            }
        });
    });

}