
$(document).ready(function() {

	$('.check_modified_inputs .form-control').click(function() {

		$(this).addClass('input_modified');
		$(this).parent().parent().find('.line_modified').val('1');

	});

	$('.check_modified_inputs .mce-tinymce, .check_modified_inputs .form-control').hover(function() {

		$(this).parent().parent().find('.line_modified').val('1');

	});

	$('.check_modified_inputs .file_td').click(function() {

		$(this).addClass('input_modified');
		$(this).parent().find('.line_modified').val('1');

	});


});