/*
Script by : Clem
--------------------------------------------
||       Gestion modale inscription       ||
--------------------------------------------
*/


if($('#modal_modification').length) {

    // GESTION DU REMPLISSAGE DE LA MODALE AU CLIC SUR UN BOUTON DE MODIFICATION
    $('.event_table .person.admin').click(function (e) { 
        e.preventDefault();
        
        // Récupération des informations de l'inscription
        clicked_element = $(this);
        place_nb = $(this).prevAll().length + 1;
        event_id = $(this).parents('.event_container').data('eventid');
        poste_id = $(this).parent().data('posteid');
        titre_event = $(this).parents('.event_container').find('.titre_event').html();
        nom_inscrit = $(this).find('.nom').html();
        if(nom_inscrit == 'Poste libre') { nom_inscrit = ''; }
        poste_demande = $(this).parents('.event_table').find('.thead .table-cell:nth-child('+poste_id+')').html();
        //console.log(titre_event);
        //console.log('poste_id : '+poste_id);
        //console.log('place_nb : '+place_nb);
        
        // Génération de l'insc_string
        insc_string = '';
        $i = 0;
        $(this).parent().find('.person').each(function () { 
            $i++;
            nom = $(this).find('.nom').html();
            if(nom == 'Poste libre') { nom = 'empty' }
            
            if($i == place_nb) {
                insc_string += '%currentinsc%|';
            } else {
                insc_string += nom+'|';
            }
        });
        insc_string = insc_string.slice(0,-1);

        // Mise à jour et affichage de la modale de confirmation
        $('#modal_modification').find('.eventname_field').html(titre_event);
        $('#modal_modification').find('.poste_field').html(poste_demande);
        $('#modal_modification').find('.nominscrit_field').html(nom_inscrit);
        $('#modal_modification').find('.input_name_modif').val(nom_inscrit);
        $('#modal_modification').find('.hidden_insc_string').html(insc_string);
        $('#modal_modification').find('.hidden_eventid').html(event_id);
        $('#modal_modification').find('.hidden_posteid').html(poste_id);
        $('#modal_modification').modal('show');
        
    });
    
    // GESTION DE L'ENVOI DES DONNÉES À LA CONFIRMATION DE MODIFICATION
    $('#modal_modification .btn_modifier').click(function (e) { 
        e.preventDefault();

        // Récupération infos
        nom_event = $('#modal_modification .eventname_field').html();
        poste_demande = $('#modal_modification .poste_field').html();
        event_id = $('#modal_modification .hidden_eventid').html();
        poste_id = $('#modal_modification .hidden_posteid').html();
        insc_string = $('#modal_modification .hidden_insc_string').html();
        nom_benevole = $('#input_name_modif').val();

        // Traitement données avant envoi
        insc_string = insc_string.replace('%currentinsc%', nom_benevole);

        ajax_url = '/php/add_inscription.php?nom_event='+nom_event+'&poste_demande='+poste_demande+'&event_id='+event_id+'&poste_id='+poste_id+'&insc_string='+insc_string+'&nom_benevole='+nom_benevole;
        sanitized_ajax_url = encodeURI(ajax_url);
        sanitized_ajax_url = sanitized_ajax_url.replace('#', '&num;');

        $.ajax({
            type: 'GET',
            url: sanitized_ajax_url,
            success: function(data) {
                //console.log('AJAX SUCCESS !');
                //console.log(data);

                $('#modal_modification').modal('hide');
                $(clicked_element).find('.nom').html(nom_benevole);
                $(clicked_element).removeClass('empty');
                $(clicked_element).addClass('taken');
                $(clicked_element).addClass('recent');

                $('.modif_message.ok').addClass('active');
                setTimeout(function(){ $('.modif_message.ok').removeClass('active'); }, 1000);

                if(debug_mode == true) { 
                    console.log('Succès de la modification de l\'inscription !'); 
                } 
            },
            error: function(data) {
                //console.log('AJAX ERROR...');
                //console.log(data);
                
                $('.modif_message.failed').addClass('active');
                setTimeout(function(){ $('.modif_message.failed').removeClass('active'); }, 2000);

                if(debug_mode == true) { 
                    console.log('Echec de la modification de l\'inscription...'); 
                } 
            }
        });
    });

    // GESTION DE L'ENVOI DES DONNÉES À LA CONFIRMATION DE DESINSCRIPTION
    $('#modal_modification .btn_desinsc').click(function (e) { 
        e.preventDefault();

        // Récupération infos
        nom_event = $('#modal_modification .eventname_field').html();
        poste_demande = $('#modal_modification .poste_field').html();
        event_id = $('#modal_modification .hidden_eventid').html();
        poste_id = $('#modal_modification .hidden_posteid').html();
        insc_string = $('#modal_modification .hidden_insc_string').html();
        nom_benevole = 'empty';

        // Traitement données avant envoi
        insc_string = insc_string.replace('%currentinsc%', nom_benevole);

        ajax_url = '/php/add_inscription.php?nom_event='+nom_event+'&poste_demande='+poste_demande+'&event_id='+event_id+'&poste_id='+poste_id+'&insc_string='+insc_string+'&nom_benevole='+nom_benevole;
        sanitized_ajax_url = encodeURI(ajax_url);
        sanitized_ajax_url = sanitized_ajax_url.replace('#', '&num;');

        $.ajax({
            type: 'GET',
            url: sanitized_ajax_url,
            success: function(data) {
                $('#modal_modification').modal('hide');
                $(clicked_element).find('.nom').html('Poste libre');
                $(clicked_element).removeClass('taken');
                $(clicked_element).addClass('empty');
                $(clicked_element).addClass('recent');

                $('.modif_message.ok').addClass('active');
                setTimeout(function(){ $('.modif_message.ok').removeClass('active'); }, 1000);

                if(debug_mode == true) { 
                    console.log('Succès de la désinscription !'); 
                } 
            },
            error: function(data) {
                //console.log(data);
                
                $('.modif_message.failed').addClass('active');
                setTimeout(function(){ $('.modif_message.failed').removeClass('active'); }, 2000);

                if(debug_mode == true) { 
                    console.log('Echec de la désinscription...'); 
                } 
            }
        });
    });

    // GESTION DE L'ENVOI DES DONNÉES À LA CONFIRMATION DE SUPPRESSION
    $('#modal_modification .btn_supprimer').click(function (e) { 
        e.preventDefault();

        // Récupération infos
        nom_event = $('#modal_modification .eventname_field').html();
        poste_demande = $('#modal_modification .poste_field').html();
        event_id = $('#modal_modification .hidden_eventid').html();
        poste_id = $('#modal_modification .hidden_posteid').html();
        insc_string = $('#modal_modification .hidden_insc_string').html();

        // Traitement données avant envoi
        insc_string = insc_string.replace('%currentinsc%', '');
        insc_string = insc_string.replace('||', '|');

        ajax_url = '/php/add_inscription.php?nom_event='+nom_event+'&poste_demande='+poste_demande+'&event_id='+event_id+'&poste_id='+poste_id+'&insc_string='+insc_string;
        sanitized_ajax_url = encodeURI(ajax_url);
        sanitized_ajax_url = sanitized_ajax_url.replace('#', '&num;');

        $.ajax({
            type: 'GET',
            url: sanitized_ajax_url,
            success: function(data) {
                $('#modal_modification').modal('hide');
                $(clicked_element).find('.nom').html('Poste libre');
                $(clicked_element).remove();

                $('.suppr_message.ok').addClass('active');
                setTimeout(function(){ $('.suppr_message.ok').removeClass('active'); }, 1000);

                if(debug_mode == true) { 
                    console.log('Succès de la suppression !'); 
                } 
            },
            error: function(data) {
                //console.log(data);
                
                $('.suppr_message.failed').addClass('active');
                setTimeout(function(){ $('.suppr_message.failed').removeClass('active'); }, 2000);

                if(debug_mode == true) { 
                    console.log('Echec de la suppression...'); 
                } 
            }
        });
    });
}