
$(document).ready(function() {

	$('.open_message').click(function() {
		
		// Récupération des infos du message
		sujet = $(this).find('.sujet').html();
		message = $(this).find('.message').html();
		email = $(this).find('.email a').attr('href');
		mailto_parameters = '?subject=Réponse à : '+sujet+'&body=%0D%0A%0D%0A------------------------------------%0D%0AVotre message :%0D%0A'+message;

		if(debug_mode == true) { 
			console.log('Sujet : '+sujet);
			console.log('Message : '+message);
			console.log('Email : '+email);
		}

		$('#modal_message').find('.sujet').html(sujet);
		$('#modal_message').find('.message').html(message);
		$('#modal_message').find('.btn_rep').attr('href', email+mailto_parameters);

		// Afficher la modale de confirmation
		$('#modal_message').modal('toggle');

	});
});