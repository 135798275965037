/*
Script by : Clem
--------------------------------------------
||       Gestion modale trombinoscope     ||
--------------------------------------------
*/


if($('#modal_trombinoscope').length) {

    // GESTION DU REMPLISSAGE DE LA MODALE AU CLIC SUR UN BOUTON DE MODIFICATION
    $('.trombi_bloc').click(function (e) { 
        e.preventDefault();
        
        // Récupération des informations de l'inscription
        clicked_element = $(this);
        infos = $(this).find('.infos').html();

        // Mise à jour et affichage de la modale de confirmation
        $('#modal_trombinoscope').find('.modal-body').html(infos);
        $('#modal_trombinoscope').modal('show');
    });
    
}